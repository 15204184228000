.product-info-group {
    text-align: center;
    padding: 40px 20px;
  }
  
  .product-card {
    border: 1px solid #E1E1E1;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #FFF;
    margin-bottom: 20px; /* Space between each card */
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center; /* Center text content */
  }
  
  .product-icon {
    font-size: 30px;
    margin-bottom: 15px;
    color: #2B3B17; /* Custom color */
  }
  
  .product-title {
    font-size: 1.5rem;
    color: #2B3B17; /* Custom color */
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .product-text {
    font-size: 1rem;
    color: #4A4A4A;
    margin-bottom: 20px; /* Ensure space between text and bottom */
    line-height: 1.5; /* Improve readability */
  }
  
  .product-link {
    font-size: 1rem;
    color: #FF6C00; /* Custom link color */
    text-decoration: underline;
  }
  
  .responsive-image-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .responsive-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .slogan-overlay-container {
    position: relative;
    text-align: center;
    width: 100%;
    max-height: 500px;
    overflow: hidden;
  }
  
  .slogan-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(89, 71, 71, 0.7);
    padding: 30px;
    border-radius: 8px;
    width: 90%;
    max-width: 800px;
  }
  
  .slogan-text {
    color: #fff;
    font-size: 2.5rem;
    font-weight: bold;
    margin: 0;
    line-height: 1.3;
  }
  
  /* Responsive Breakpoints */
  @media (max-width: 1200px) {
    .slogan-text {
      font-size: 2rem;
    }
  }
  
  @media (max-width: 992px) {
    .slogan-overlay {
      padding: 25px;
    }
  
    .slogan-text {
      font-size: 1.8rem;
    }
  }
  
  @media (max-width: 768px) {
    .product-info-group {
      padding: 30px 15px;
    }
  
    .slogan-overlay {
      padding: 20px;
      width: 95%;
    }
  
    .slogan-text {
      font-size: 1.5rem;
    }
  
    .responsive-image {
      max-height: 400px;
    }
  }
  
  @media (max-width: 576px) {
    .product-info-group {
      padding: 20px 10px;
    }
  
    .slogan-overlay {
      padding: 15px;
    }
  
    .slogan-text {
      font-size: 1.2rem;
    }
  
    .responsive-image {
      max-height: 300px;
    }
  }
  